






















import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Quote',
  props: {
    description: {
      type: String,
      default: '',
    },
    writer: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    return {
      background: computed(() => props.backgroundColor?.toLowerCase() || ''),
    };
  }
});
